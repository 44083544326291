// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import {
   
    useNavigate,
   
} from "react-router-dom";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDODDISJp8N0F8vzglTAj40tpTjP4HWkyQ",
    authDomain: "turkparawix.firebaseapp.com",
    projectId: "turkparawix",
    storageBucket: "turkparawix.appspot.com",
    messagingSenderId: "523754878890",
    appId: "1:523754878890:web:ed7dfa5e818a8438141dd3",
    measurementId: "G-EGT430CV68"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
// export const analytics = getAnalytics(app); 
// export const auth = getAuth(app);


// auth.languageCode = 'tr';
// window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
//     'size': 'invisible',
//     'callback': (response) => {
//         // reCAPTCHA solved, allow signInWithPhoneNumber.
//         // onSignInSubmit();
//     }
// }, auth);



// onAuthStateChanged(auth, (user) => {
//     if (user) {
       
//         const uid = user.uid;
       
//     } else {
//         console.log("onAuthStateChanged")
//         // window.location.href = "/giris";
//     } 
// });


