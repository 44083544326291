import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GeneralLayout from './layout/GeneralLayout';
import PaymentPage from './pages/PaymentPage';
import PaymentResultPage from './pages/PaymentResultPage';
import NotFoundPage from './pages/NotFound';

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: <GeneralLayout />,
            children: [
                { path: 'payment/result', element: <PaymentResultPage /> },
                { path: 'payment/:id', element: <PaymentPage /> },
                { path: '404', element: <NotFoundPage /> },
                { path: '/', element: <NotFoundPage /> },
                { path: '*', element: <Navigate to="/404" /> }
            ]
        },
        { path: '*', element: <Navigate to="/404" replace /> }
    ]);
}
