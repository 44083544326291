import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const url ="https://pos.param.com.tr/Tahsilat/v2/images/param-logo-v3-beyaz.svg";
  // const url = "https://param.com.tr/images/param-logo-v3-mor.svg";
  return <Box component="img" src={url} sx={{ height: 40, ...sx }} />;
}
