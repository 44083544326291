import { Link as RouterLink, useParams } from 'react-router-dom';
import { Fragment, useEffect, useState } from "react";

import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography, TextField, CircularProgress, Divider, Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputMask from "react-input-mask";
import Alert from '@mui/material/Alert';


import Page from '../components/Page';

import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';

import NumberFormat from 'react-number-format';

import { LoadingButton } from '@mui/lab';

import GeneralApi from "../api/generalApi";


import { auth, db } from "../config";

import { collection, doc, getDoc, addDoc } from "firebase/firestore";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Iframe from '@trendmicro/react-iframe';

import Cards from './../components/CreditCards'

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
    // maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    minWidth: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(20, 0)
}));

export default function PaymentPage() {




    const { id } = useParams()

    const [transaction, setTransaction] = useState(null);
    const [isOnPayment, setOnPayment] = useState(null);
    const [isFetchingInstallments, setFetchingInstallments] = useState(null);
    const [installmentsInfo, setInstallmentsInfo] = useState(null);
    const [turkParaResult, setTurkParaResult] = useState(null);
    const [paymentResult, setPaymentResult] = useState(null);
    const [selectedInstallment, setSelectedInstallment] = useState(null);

    const [open, setOpen] = useState(false);
    const [cancelDialogOpened, setCancelDialogOpened] = useState(false);

    const [selectedIndex, setSelectedIndex] = useState(1);
    const [cvc, setCvc] = useState("");
    const [expiry, setExpiry] = useState("");
    const [focus, setFocus] = useState("");
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");

    useEffect(() => {
        const handler = event => {
            const data = event.data;
            console.log("message data", data);
            if (data.type == "payment") {
                setPaymentResult(data);

                if (data.isSuccess) {

                    console.log("payment is success", transaction)
                    window.location.replace(transaction.order.returnUrls.successUrl);
                } else {
                    console.log("payment is not success", transaction)
                    window.location.replace(transaction.order.returnUrls.errorUrl);
                }
            } else {
                console.log("message type", data.type);
            }

        }

        window.addEventListener("message", handler)

        // clean up
        return () => window.removeEventListener("message", handler)
    }, [transaction]) // 

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;

        setOpen(false);
        setTurkParaResult(null)
        setPaymentResult(null)
    };


    const _onSubmit = async (values, { setSubmitting }) => {


        const { name, cardNumber, cvc, expiry } = values;



        setOnPayment(true);
        setOpen(true);
        try {


            const params = {
                name, cardNumber, cvc, expiry,
                transactionId: transaction.id,
                installment: selectedInstallment
            };
            const response = await GeneralApi.post("payment", params);

            console.log("turk para result", response);
            setTurkParaResult(response);



            // setTimeout(() => {

            //     if (response.result == "success")
            //         // window.location.replace(transaction.order.returnUrls.successUrl);
            //         window.location.replace(response.redirectUrl)
            //     else {
            //         window.location.replace("http://google.de");
            //         // window.location.replace(transaction.order.returnUrls.errorUrl);

            //     }
            // }, 1500);
        } catch (e) {
            alert(e);
        } finally {
            setOnPayment(false);
        }
    }
    let lastInstallmentCardNumber;
    const _fetchInstallments = async (rawCardNumber) => {
        if (rawCardNumber.length < 6) return;
        if (isFetchingInstallments) return;
        let cardNumber = rawCardNumber.substring(0, 6);
        if (lastInstallmentCardNumber && lastInstallmentCardNumber == rawCardNumber) {
            return;
        }
        lastInstallmentCardNumber = cardNumber;
        setFetchingInstallments(true);
        try {

            const params = {
                transactionId: id,
                cardNumber
            };
            const response = await GeneralApi.post("installments", params);

            console.log("result", response);
            setInstallmentsInfo(response);
            const installments = response.installments;

            setSelectedInstallment(1)


        } catch (e) {
            alert(e);
        } finally {
            setFetchingInstallments(false);
        }
    }



    const FormSchema = Yup.object().shape({
        name: Yup.string('İsminizi girmelisiniz').required('İsminiz gerekli'),
        cardNumber: Yup.string().required('Kart numarası gerekli'),
        cvc: Yup.string().required('CVC gereklidir.'),
        expiry: Yup.string().required('Son kullanma tarihin gereklidir.')
    });
    const formik = useFormik({
        initialValues: {
            name: "",//'Albert Einstein',
            cardNumber: "",//'4022774022774026',
            cvc: "",//'000',
            expiry: "",//'12/26',
        },
        validationSchema: FormSchema,
        onSubmit: _onSubmit
    });


    useEffect(() => {

        if (transaction) return;
        const docRef = doc(db, "transactions", id);
        getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                setTransaction({ id: docSnap.id, ...docSnap.data() })


            } else {
                console.log("No such document!");
                alert("TRansaction bulunamadı");
            }
        })



    }, [transaction, setTransaction]);

    if (transaction == null) return <Fragment></Fragment>


    const { order } = transaction;
    const { totalAmount, currency } = order.description;
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const _getAmount = (rate) => {

        if (installmentsInfo == null) {
            return totalAmount / 100.00;
        }
        var installmentRate = rate;

        if (!installmentRate)
            installmentRate = selectedInstallment == null ? 0 : installmentsInfo.installments[selectedInstallment];

        console.log("installment rate", rate, installmentRate);
        var result = ((1 + (parseFloat(installmentRate) / 100.00)) * totalAmount / 100.00)

        console.log("Amount", result);

        return result;
    }

    const _selectInstallment = (event, installmentKey) => {
        setSelectedInstallment(installmentKey)
    }

    const _onChangeCardNumber = (event) => {

        let rawCardNumber = event.target.value.replaceAll("*", "")
        formik.setFieldValue("cardNumber", rawCardNumber)
        setNumber(rawCardNumber);

        let cardNumber = rawCardNumber.replaceAll(" ", "");
        if (cardNumber.length < 6) {
            if (installmentsInfo != null) {
                setInstallmentsInfo(null)
                setSelectedInstallment(null)
            }
        } else {

            _fetchInstallments(cardNumber);
        }
    }

    const _onChangeCardCvc = (event) => {

        formik.setFieldValue("cvc", event.target.value)
        setCvc(event.target.value);
    }

    const _onChangeCardExpiry = (event) => {

        formik.setFieldValue("expiry", event.target.value)
        setExpiry(event.target.value);
    }

    const _onChangeCardName = (event) => {

        formik.setFieldValue("name", event.target.value)
        setName(event.target.value);
    }

    const _onInputFocus = (e) => {
        setFocus(e.target.name);
    }

    const _cancel = async () => {
        setCancelDialogOpened(true)
        await GeneralApi.post(`payment/cancel/${id}`);
        window.location.replace(order.returnUrls.cancelUrl);

    }

    return (
        <RootStyle title="ParamPOS Ödeme">
            <Container maxWidth="sm">
                <ContentStyle>

                    <Stack spacing={2} direction="column"
                    // divider={<Divider orientation="vertical" flexItem />}
                    >
                        <Stack spacing={2} direction="row"
                        // divider={<Divider orientation="vertical" flexItem />}
                        >
                            <Cards
                                cvc={cvc}
                                expiry={expiry}
                                focused={focus}
                                name={name}
                                number={number}
                            />
                            <Stack sx={{ mb: 5, flexGrow: 1, minWidth: 400 }}>
                                <FormikProvider value={formik}>


                                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                                        <Stack spacing={3} direction="column" sx={{ mb: 1 }}>
                                            <TextField
                                                fullWidth
                                                autoComplete="name"
                                                type="text"
                                                label="İsim Soyisim"
                                                {...getFieldProps('name')}
                                                onChange={_onChangeCardName}
                                                onFocus={_onInputFocus}
                                                error={Boolean(touched.name && errors.name)}
                                                helperText={touched.name && errors.name}
                                            />

                                            <InputMask
                                                mask="9999 9999 9999 9999"
                                                maskChar="*"
                                                fullWidth
                                                autoComplete="cardNumber"
                                                label="Kart Numarası"

                                                {...getFieldProps('cardNumber')}
                                                onFocus={_onInputFocus}
                                                onChange={_onChangeCardNumber}
                                                error={Boolean(touched.cardNumber && errors.cardNumber)}
                                                helperText={touched.cardNumber && errors.cardNumber}

                                            >
                                                {(inputProps) => <TextField{...inputProps} />}
                                            </InputMask>
                                            {/* <TextField
                                            fullWidth
                                            type="number"
                                            label="Kart Numarası"
                                            {...getFieldProps('cardNumber')}
                                            onChange={_onChangeCardNumber}
                                            onFocus={_onInputFocus}
                                            error={Boolean(touched.cardNumber && errors.cardNumber)}
                                            helperText={touched.cardNumber && errors.cardNumber}
                                        /> */}
                                        </Stack>
                                        <Stack spacing={3} direction="row" sx={{ mb: 1 }}>
                                            <InputMask
                                                mask="99/99"
                                                maskChar=" "
                                                fullWidth
                                                autoComplete="expiry"
                                                label="Son Kullanma Tarihi"
                                                {...getFieldProps('expiry')}
                                                onFocus={_onInputFocus}
                                                onChange={_onChangeCardExpiry}
                                                error={Boolean(touched.expiry && errors.expiry)}
                                                helperText={touched.expiry && errors.expiry}

                                            >
                                                {(inputProps) => <TextField{...inputProps} />}
                                            </InputMask>

                                            <InputMask
                                                mask="999"
                                                maskChar=" "
                                                fullWidth
                                                autoComplete="cvc"
                                                label="CVC"

                                                {...getFieldProps('cvc')}
                                                onFocus={_onInputFocus}
                                                onChange={_onChangeCardCvc}
                                                error={Boolean(touched.cvc && errors.cvc)}
                                                helperText={touched.cvc && errors.cvc}

                                            >
                                                {(inputProps) => <TextField{...inputProps} />}
                                            </InputMask>
                                            {/* <TextField
                                            fullWidth
                                            autoComplete="cvc"
                                            type="text"
                                            label="CVC"
                                            multiline

                                            {...getFieldProps('cvc')}
                                            onFocus={_onInputFocus}
                                            onChange={_onChangeCardCvc}
                                            error={Boolean(touched.cvc && errors.cvc)}
                                            helperText={touched.cvc && errors.cvc}
                                        /> */}


                                            {/* <TextField
                                            fullWidth
                                            autoComplete="expiry"
                                            type="text"
                                            label="Son Kullanma Tarihi"
                                            multiline
                                            {...getFieldProps('expiry')}
                                            onFocus={_onInputFocus}
                                            onChange={_onChangeCardExpiry}
                                            error={Boolean(touched.expiry && errors.expiry)}
                                            helperText={touched.expiry && errors.expiry}
                                        /> */}
                                        </Stack>
                                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>

                                            <Button fullWidth color="error" variant="text" onClick={_cancel} >Ödemeyi İptal Et</Button>
                                            <LoadingButton
                                                fullWidth
                                                // size="large"
                                                disabled={!installmentsInfo}
                                                type="submit"
                                                variant="contained"
                                                loading={isOnPayment}>
                                                {_getAmount().toFixed(2)} ₺ ÖDE
                                            </LoadingButton>
                                        </Stack>

                                    </Form>
                                </FormikProvider>

                            </Stack>


                        </Stack>
                        <Box>
                            {(() => {

                                if (installmentsInfo == null) {
                                    let count = 6 - number.replaceAll(" ", "").length;

                                    if (count == 0) {
                                        return <Alert severity="info">Taksit bilgileri alınıyor.</Alert>
                                        return <Typography style={{ align: "center", flexGrow: 1, }}>Taksit bilgileri alınıyor.</Typography>
                                    }


                                    return <Alert severity="info">Taksitleri görmek için kart bilgilerinizi giriniz.</Alert>
                                    // return <Alert severity="info">{`Taksit bilgilerini öğrenmenize ${6 - number.replaceAll(" ", "").length} adım kaldı.`}</Alert>

                                    return <Typography align="center" style={{ align: "center", flexGrow: 1, }}>{`Taksit bilgilerini öğrenmenize ${6 - number.replaceAll(" ", "").length} adım kaldı.`}</Typography>

                                } else if (installmentsInfo.result != "success") {
                                    return <Alert severity="error">Taksit bilgisi alınamadı</Alert>


                                }
                                else {
                                    return (< TableContainer component={Paper} >
                                        <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ width: 120 }}>Taksit Sayısı</TableCell>
                                                    <TableCell align="right">Oran</TableCell>
                                                    <TableCell style={{ width: 150 }} align="right">Toplam Ödeme</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {Object.keys(installmentsInfo.installments).map((key) => {

                                                    let installmentRate = installmentsInfo.installments[key]
                                                    return <TableRow
                                                        key={key}
                                                        selected={selectedInstallment == key}
                                                        onClick={(event) => _selectInstallment(event, key)}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >

                                                        <TableCell align="right">{`${key==1?'Peşin':key}`}</TableCell>
                                                        <TableCell align="right">{`${installmentRate}%`}</TableCell>
                                                        <TableCell align="right">{_getAmount(installmentRate).toFixed(2)} {currency}</TableCell>

                                                    </TableRow>
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    )
                                }
                            })()
                            }
                        </Box>

                    </Stack>
                </ContentStyle>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth={true}
                    maxWidth="lg"
                    disableEscapeKeyDown={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/* <DialogTitle id="alert-dialog-title">
                        {"Sonuç"}
                    </DialogTitle> */}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {(() => {

                                if (turkParaResult == null) return <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    minHeight="100vh"
                                >
                                    <CircularProgress />
                                </Box>
                                if (turkParaResult.result == "success") {
                                    return <Iframe src={turkParaResult.redirectUrl} height={400}></Iframe>

                                }
                                else {
                                    return "Ödeme işlemi Başarısız \n\n" + turkParaResult.errorMessage;
                                }
                            })()}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {(() => {
                            if (paymentResult != null && paymentResult.isSuccess == false) {
                                return <Button onClick={handleClose} autoFocus> Tamam </Button>
                            }
                        })()}

                    </DialogActions>
                </Dialog>

                <Dialog
                    open={cancelDialogOpened}
                    onClose={handleClose}
                    fullWidth={true}
                    maxWidth="lg"

                    disableEscapeKeyDown={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/* <DialogTitle id="alert-dialog-title">
                        {"Sonuç"}
                    </DialogTitle> */}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">

                            <Stack alignItems="center" justifyContent="space-around" sx={{ my: 2, height: 200, }}>
                                <Box >İşleminiz iptal ediliyor; şimdi mağazaya yönlendiriliyorsunuz; lütfen bekleyin.</Box>
                                <CircularProgress />
                            </Stack>
                        </DialogContentText>
                    </DialogContent>

                </Dialog>
            </Container>
        </RootStyle >
    );
}
