
import Box from '@mui/material/Box';

export default function NotFoundPage() {
    return <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
    >
        <div>Aradığınız sayfa bulunamadı.</div>
    </Box>
}