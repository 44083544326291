import * as React from "react";
import { useSearchParams } from "react-router-dom";

import Box from '@mui/material/Box';

export default function PaymentResultPage() {

    let [searchParams, setSearchParams] = useSearchParams();
    let message = searchParams.get("resultMessage");
    let isSuccess = searchParams.get("isSuccess") == true || searchParams.get("isSuccess") == "true";
    console.log("Payment result page", message);
    window.top.postMessage(
        {
            type: "payment",
            isSuccess,
            message
        },
        '*'
    );
    return  <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
    >
        <div>{isSuccess ? "Ödeme başarılı, şimdi mağazaya yönlendiriliyorsunuz." : message}</div>
    </Box>
}