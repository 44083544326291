import axios from 'axios';
import Error from '../models/error';


const localHostApi = "http://localhost:5000/api";
const prodApiUrl = "https://wix.param.com.tr/api";

const post = async (path, data) => {

    try {
        const apiUrl = window.location.hostname=="localhost"? localHostApi:prodApiUrl;
        var response = await axios.post(`${apiUrl}/${path}`, data)
        return _getResponseData(response);
    } catch (e) {
        throw e; //todo handle errors
    }
}

const _getResponseData = (response) => {


    if (response.status == "200") {
        return response.data;
    }

    throw Error(parseInt(response.status),null, response.data);
}

export default { post }