export default class Error {
    constructor(statusCode, code, message) {
        this.statusCode = statusCode;
        this.errorCode = code;
        this.errorMessage = message;
    }

    toJson(){
        return { errorCode: this.errorCode, errorMessage: this.errorMessage}
    }
}